@import url(https://use.typekit.net/npc2vha.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* BOILERPLATE */

:root {
	--cyan1: #45CFFB;
	--cyan2: #BEEEFE;
	--orange1: #E75950;
	--orange2: #FE938D;
	--yellow1: #F6BD4E;
	--yellow2: #FAD992;
	--gray1: #222529;
	--gray2: #2B2E32;
	--gray3: #3E434A;
	--gray4: #4F5358;
	--gray5: #97999B;
	--gray6: #CBCCCD;
	--white: #FFFFFF;
}

html, body {
	background-color: #222529;
	background-color: var(--gray1);
    color: #FFFFFF;
    color: var(--white);
	font-family: 'Effra', sans-serif;
	font-size: 1em;
	line-height: 1.5;
    margin: 0;
}
@media screen and (max-width: 1100px) {
	html, body {
		font-size: 0.95em;
	}
}

h1:empty, h2:empty, p:empty, a:empty, br, .display-none {
	display: none !important;
}

/* textarea {
	position: absolute;
	top: -99999px;
} */

/* TYPOGRAPHY */

h2 {
	font-size: 1.5em;
	line-height: 1.25em;
}

.location-name {
	display: inline-block;
	width: 86%;
}

.iata-code {
	color: #97999B;
	color: var(--gray5);
	float: right;
}

/* WRAPPERS & SECTIONS */

/* .app-wrapper {} */

.left-section {
	background: #2B2E32;
	background: var(--gray2);
	box-sizing: border-box;
	padding: 2em;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: 400px;
	
}
@media screen and (max-width: 800px) {
	.left-section {
		position: relative;
		width: auto;
	}
}

.right-section {
	background: #222529;
	background: var(--gray1);
	left: 400px;
	padding: 2em;
	position: absolute;
	right: 0;
	top: 0;
}
@media screen and (max-width: 800px) {
	.right-section {
		position: static;
	}
}

.footer {
	bottom: 2em;
	position: absolute;
	width: calc(100% - 4em)
}

.copyright {
	color: #97999B;
	color: var(--gray5);
	float: right;
}

.result-item {
	border: 2px solid #2B2E32;
	border: 2px solid var(--gray2);
	border-radius: 0.5em;
	display: flex;
	margin-bottom: 2em;
	padding: 1em 2em;
	width: calc(100vw - 400px - 4em);
}
@media screen and (max-width: 1300px) {
	.result-item {
		flex-direction: column;
	}
}
@media screen and (max-width: 800px) {
	.result-item {
		width: calc(100vw - 4em);
	}
}

@media screen and (max-width: 1300px) {
	.result-headline {
		border-bottom: 1px solid #4F5358;
		border-bottom: 1px solid var(--gray4);
	}
}

.result-details {
	display: flex;
}
@media screen and (max-width: 1100px) {
	.result-details {
		flex-direction: column;
	}
}

.flight {
	padding-left: 4em;
}

.flight:last-child {
	padding-left: 2em;
}
@media screen and (max-width: 1300px) {
	.flight, .flight:last-child {
		padding-left: 0;
	}
}

.grid-wrapper {
	border-bottom: 1px solid #4F5358;
	border-bottom: 1px solid var(--gray4);
	display: grid;
	grid-template-columns: auto auto auto;
	margin-bottom: 1em;
	padding-bottom: 1em;
}

.flight-detail-span {
	padding-right: 1.5em;
}

.about-wrapper {
	color: #CBCCCD;
	color: var(--gray6);
	font-size: 1.5em;
	line-height: 1.5em;
	max-width: 50rem;
	padding: 2em;
}

/* INPUTS */

:focus {
	outline: none;
}

.input-wrapper {
	position: relative;
}

input {
	background: transparent;
	border: 2px solid #4F5358;
	border: 2px solid var(--gray4);
	border-radius: 0.5em;
	box-sizing: border-box;
	color: #FFFFFF;
	color: var(--white);
	font-family: 'Effra', sans-serif;
	font-size: 1em;
	height: 4em;
	margin-bottom: 1em;
	padding: 2em 1em 0.75em;
	width: 100%;
}

input:focus {
	border: 2px solid #45CFFB;
	border: 2px solid var(--cyan1);
}

input[type="date"]::-webkit-calendar-picker-indicator {
	font-size: 1em;
	opacity: 0;
	position: relative;
	right: -3px;
	top: -8px;
  }

.date-input-wrapper:after {
	background: url('https://meet-me-in.com/wp-content/uploads/2020/07/Calendar-Icon-01.png') no-repeat;
	background-size: 90%;
	bottom: 1.35em;
	content:'';
	font-size: 1.5em;
	height: 1.2em;
	position: absolute;
	right: 0.5em;
	width: 1.2em;
	z-index:-1;
}

label {
	color: #CBCCCD;
	color: var(--gray6);
	font-size: 0.85em;
	padding-left: 1.25em;
	position: absolute;
	top: 0.75em;
}

/* BUTTONS & LINKS */

a {
	color: #45CFFB;
	color: var(--cyan1);
	margin-right: 1em;
}

.link-button {
	background: none !important;
	border: none !important;
	color: #45CFFB;
	color: var(--cyan1);
	font-size: inherit;
	font-weight: inherit;
	height: auto !important;
	margin: 0 !important;
	padding: 0 !important;
	text-decoration: underline;
	width: auto !important;
}

.button-link {
	border-top: 0.25em solid transparent;
	display: inline-block;
	font-weight: 500;
	height: 2.5em;
	padding-top: 0.35em;
	text-decoration: none;
}

button, .button-link {
	background: #45CFFB;
	background: var(--cyan1);
	border-radius: 0.5em;
	box-sizing: border-box;
	color: #222529;
	color: var(--gray1);
	cursor: pointer;
	font-family: 'Effra', sans-serif;
	font-size: 1em;
	margin-bottom: 1.5em;
	padding-left: 2em;
	padding-right: 2em;
	text-align: center;
}

button {
	border: none;
	font-weight: 700;
	height: 4em;
	width: 100%;
}

button:focus, .button-link:focus {
	border-top: 0.25em solid rgba(0,0,0,0.3);
}

button:hover, .button-link:hover {
	background: #BEEEFE;
	background: var(--cyan2);
}

button[disabled], .button-link[disabled] {
	background: #222529;
	background: var(--gray1);
	color: #4F5358;
	color: var(--gray4);
	cursor: not-allowed;
}

/* AUTOCOMPLETE */

* {
  box-sizing: border-box;
}

.autocomplete {
  position: relative;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #97999B;
  border: 1px solid var(--gray5);
  border-bottom: none;
  border-top: none;
  z-index: 99;
  top: 100%;
  left: 0;
  right: 0;
}

.autocomplete-items > div {
  color: #222529;
  color: var(--gray1);
  padding: 1em;
  cursor: pointer;
  background-color: #FFFFFF;
  background-color: var(--white); 
  border-bottom: 1px solid #97999B; 
  border-bottom: 1px solid var(--gray5); 
}

.autocomplete-items > div:hover {
  background-color: #BEEEFE;
  background-color: var(--cyan2); 
}

/* When navigating through the items using the arrow keys */
.autocomplete-active {
  background-color: #BEEEFE !important;
  background-color: var(--cyan2) !important; 
}

/* IMAGES */

svg {
    fill: #45CFFB;
    fill: var(--cyan1);
}

/* STATES */

.state {
	align-items: center; 
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	min-height: calc(100vh - 4em);
}
@media screen and (max-width: 800px) {
	.state {
		min-height: 0;
	}
}

.state-inner-flex-wrapper {
	max-width: 40em;
	text-align: center;
}

/* SNACKBARS */

.snackbar {
	background: #FFFFFF;
	background: var(--white);
	border-radius: 0.5em 0.5em 0 0;
	color: #222529;
	color: var(--gray1);
	opacity: 0.8;
	padding: 0.5em 1em;
	position: fixed;
	right: 2em;
	bottom: -3em;
	transition: bottom 0.25s;
}
